.discord {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    text-decoration: none;
    color: #444;
    margin-bottom: 10px;
}

.discord h1 {
    margin: 0;
    font-size: 14px;
    padding: 0;
    line-height: 120%;
    text-decoration: none;
}