.common-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    width: 100%;
}

.common-title h1 {
    font-style: italic;
    font-weight: 700;
    font-size: 24px;
    padding: 0;
    margin: 0 24px;
}

.common-title button {
    padding: 0;
    margin: 0 24px;
}