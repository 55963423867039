.order-card {
    position: relative;
    margin: 5px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 440px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    overflow: hidden;
}

.order-card h1 {
    font-size: 20px;
    font-weight: 700;
    padding: 0;
    margin: 0;
    line-height: 120%;
}

.order-card-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    gap: 5px;
}

.order-card-item {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
    padding: 3px 10px;
    gap: 20px;
}

.order-card-item h2 {
    font-size: 16px;
    font-weight: bold;
    padding: 0;
    margin: 0;
    line-height: 120%;
    width: 80px;
    text-align: right;
    color: grey;
}

.order-card-item p,
a {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    line-height: 120%;
    width: 100%;
    text-align: left;
}

.order-card-left {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.order-card-left img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.order-card-right {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 10px;
}

.order-card-right-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
}

.order-card-right-left h1 {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 20px;
}

.order-card-right-left h2 {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    color: grey;
    line-height: 16px;
}

.order-card-right-left p {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-align: left;
}

.order-card-right-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 5px;
}

.order-card-edit {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.order-card-edit button {
    border: none;
    background-color: transparent;
}