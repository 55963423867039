.user-profile-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 600px;
    padding: 20px;
}

.user-modal-account {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.user-modal-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* 
.user-modal-account button {
    background-color: transparent;
    color: #333;
    border: none;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    border-radius: 100px;
    padding: 4px 8px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
} */