.row-button {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 50px;
    width: 100%;
}

.card-row {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.item-input {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    gap: 2px;
}